<!--
 * @Author: your name
 * @Date: 2021-04-09 10:28:35
 * @LastEditTime: 2021-04-19 14:27:48
 * @LastEditors: Please set LastEditors
 * @Description: 排期
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\pathAllocation\components\Schedule.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      title="设置线路排期"
      :visible.sync="Schedule"
      width="90%"
    >
      <div>
        <el-form :model="form" inline>
          <el-form-item prop="user_id">
            <el-select
              v-model="form.user_id"
              placeholder="请选择员工"
              @change="handlerinfo"
            >
              <el-option
                v-for="(list, index) in userlist"
                :key="index"
                :value="list.user_id"
                :label="list.user_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="考勤组：">
            <span>{{ form.attend_name }}</span>
          </el-form-item>
          <el-form-item label="休息日：">
            <span>{{ form.rest_day }}</span>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="tiem"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerpaiqi">
              生成预排期
            </el-button>
          </el-form-item>
        </el-form>

        <el-calendar v-model="currentDate">
          <template #dateCell="{ data }" class="content">
            <p :class="data.isSelected ? 'is-selected' : ''">
              {{ data.day.split('-').slice(1).join('月') }}
              {{ data.isSelected ? '✔️' : '' }}
            </p>
            <div v-for="(list, index) in paiqidata" :key="index">
              <el-tag
                v-if="data.day == list.date && list.is_rest == 0"
                type="warning"
              >
                班
              </el-tag>
              <el-tag
                v-else-if="data.day == list.date && list.is_rest == 1"
                type="danger"
              >
                休
              </el-tag>
              <el-select
                v-if="data.day == list.date"
                v-model="list.line_id"
                placeholder="线路名称"
                style="margin-top: 10px"
              >
                <el-option
                  v-for="(item, indexs) in xianlu"
                  :key="indexs"
                  :label="item.line_name"
                  :value="item.line_id"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-calendar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" @click="handlersave">保存排期</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        Schedule: false,
        currentDate: '',
        tiem: '',
        userlist: [],
        form: {
          user_id: '',
          attend_name: '',
          rest_day: '',
        },
        url: {
          info: '/baseAdmin/line/user-line-plan',
          user: '/baseAdmin/user/index',
          attend: '/baseAdmin/line/get-attend',
          paiqi: '/baseAdmin/line/pre-plan',
          line: '/baseAdmin/line/line-plan',
          save: '/baseAdmin/line/save-plan',
        },
        paiqidata: [],
        xianlu: [],
      }
    },

    computed: {},
    watch: {
      Schedule(val) {
        if (!val) {
          this.tiem = ''
          this.paiqidata = []
        }
      },
      'form.user_id'(val) {
        this.handlerlink(val)
        this.handlersetpaiqi()
      },
    },
    created() {},
    mounted() {},
    methods: {
      handlerlink(val) {
        postAction(this.url.line, { user_id: val })
          .then((res) => {
            this.xianlu = res.data.line.rows
            console.log(res, '綫路列表', this.xianlu)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlersave() {
        let xianludata = JSON.stringify(this.paiqidata)
        postAction(this.url.save, {
          user_id: this.form.user_id,
          line_data: xianludata,
          min_date: this.tiem,
        })
          .then((res) => {
            console.log(res)
            this.$message({ type: 'success', message: res.msg })
            this.Schedule = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlersetpaiqi() {
        postAction('/baseAdmin/line/user-line-plan', {
          user_id: this.form.user_id,
          start_time: new moment().format('YYYY-MM-D'),
          end_time: new moment().add(1, 'month').format('YYYY-MM-D'),
          pageNo: 1,
          pageSize: 60,
        })
          .then((res) => {
            console.log(res.data, '排期线路')
            if (res.code == 200) {
              this.paiqidata = res.data
              if (this.paiqidata.length <= 0) {
                this.$message({
                  type: 'warning',
                  message: '未查询到线路排期',
                })
              }
            } else {
              this.paiqidata = res.data
              this.$message({
                type: 'warning',
                message: '暂无设置的排期请重新选定时间',
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerpaiqi() {
        postAction(this.url.paiqi, {
          user_id: this.form.user_id,
          line_start: this.tiem,
        })
          .then((res) => {
            console.log(res.data, '排期线路')
            if (res.code == 200 && res.data.length != 0) {
              this.paiqidata = res.data
            } else {
              this.paiqidata = res.data
              this.$message({ type: 'warning', message: '请先设置线路' })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      GetTime() {},
      getuserlist() {
        postAction(this.url.user, { pageSize: -1 })
          .then((res) => {
            this.userlist = res.data
            this.form.user_id = this.userlist[0].user_id
            this.handlerinfo()
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      getattend() {
        postAction(this.url.attend, { user_id: this.form.user_id })
          .then((res) => {
            console.log(res, '休息日')
            this.form.attend_name = res.attend_name
            this.form.rest_day = res.rest_day
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerinfo() {
        postAction(this.url.info, { user_id: this.form.user_id })
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err, '')
          })
        this.getattend()
      },
    },
  }
</script>
<style>
  .is-selected {
    color: #1989fa;
  }
  .content {
    width: auto;
    height: 100% !important;
  }
  .el-calendar-day {
    height: auto !important;
  }
</style>
