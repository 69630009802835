<!--
 * @Author: your name
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2021-04-09 15:15:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\pathAllocation\index.vue
-->
<template>
  <!-- NAME[epic=基础] 线路分配 -->
  <div class="wrapper">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select
          v-model="departmentid"
          placeholder="请选择部门"
          @change="handlerdepid"
        >
          <el-option
            v-for="item in departmentlist"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-table
          highlight-current-row
          :data="tabledata"
          style="margin-top: 30px"
          height="400px"
          @row-click="handlerrow"
        >
          <el-table-column label="选择业务员" align="center">
            <template #default="{ row }">
              {{ row.user_name }} {{ row.mobile }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="20">
        <el-table stripe :data="linelist">
          <el-table-column
            v-for="(routers, index) in clumds"
            :key="index"
            :prop="routers.prop"
            :label="routers.label"
            :width="routers.width"
            :align="routers.align"
          >
            <template v-if="routers.label == '线路顺序'" #default="{ row }">
              <el-input
                v-model="row.sort"
                :disabled="row.enid"
                placeholder="请输入线路顺序"
                @blur="Setsort(row)"
              ></el-input>
            </template>
            <template
              v-else-if="routers.label == '线路名称'"
              #default="{ row }"
            >
              <!-- <el-autocomplete
                v-model="row.line_id"
                placeholder="请输入线路名称"
                :fetch-suggestions="querySearch"
                value-key="line_name"
                @select="
                  (val) => {
                    handlerrouterdata(val, row)
                  }
                "
              ></el-autocomplete> -->
              <el-select
                v-model="row.line_id"
                filterable
                clearable
                @change="
                  (val) => {
                    handlerrouterdata(val, row)
                  }
                "
              >
                <el-option
                  v-for="list in ruterlist"
                  :key="list.line_id"
                  :label="list.line_name"
                  :value="list.line_id"
                ></el-option>
              </el-select>
            </template>
            <template
              v-else-if="routers.label == '客户数量'"
              #default="{ row }"
            >
              <span
                style="text-decoration: underline; cursor: pointer"
                @click="handlerinfo(row)"
              >
                {{ row.cust_num }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{ $index }">
              <el-button type="text" @click="handleradd($index)">
                增加
              </el-button>
              <el-button type="text" @click="handlerdelete($index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" class="row-bg row-bgs" justify="end">
          <el-button type="primary" @click="handlersave">保 存</el-button>
          <el-button type="warning" @click="handlerSetrouter">
            设置线路排期
          </el-button>
        </el-row>
      </el-col>
    </el-row>
    <cust-list ref="cust"></cust-list>
    <schedule ref="schedule"></schedule>
  </div>
</template>

<script>
  import Schedule from './components/Schedule.vue'
  import CustList from './components/CustList.vue'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'PathAllocation',
    components: { CustList, Schedule },
    data() {
      return {
        departmentid: '',
        departmentlist: [],
        tabledata: [],
        linelist: [],
        ruterlist: [],
        fanli: [
          {
            line_id: '',
            line_name: '',
            tian: '',
            sort: '',
            cust_num: '',
            enid: true,
          },
        ],
        url: {
          department: '/baseAdmin/common/depart-list',
          deparUser: '/baseAdmin/common/user-list',
          userline: '/baseAdmin/line/line-plan',
          router: '/baseAdmin/line/index',
          save: '/baseAdmin/line/line-plan-set',
          sort: '/baseAdmin/line/sort',
        },
        clumds: [
          {
            prop: 'address',
            label: '线路顺序',
            width: '80',
            align: 'center',
          },
          {
            prop: 'boss',
            label: '线路名称',
            width: '',
            align: 'center',
          },
          {
            prop: 'user_num',
            label: '客户数量',
            width: '',
            align: 'center',
          },
          {
            prop: 'tian',
            label: '完成天数',
            width: '',
            align: 'center',
          },
        ],
        user_id: '',
      }
    },
    mounted() {
      this.handlerdepartment()
      this.handlerruter()
    },

    methods: {
      //线路
      handlerruter() {
        postAction(this.url.router, { is_close: 0 })
          .then((res) => {
            console.log(res, '线路')
            this.ruterlist = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, {})
          .then((res) => {
            console.log(res)
            this.departmentlist = res.data.list
          })
          .catch((err) => {
            consloe.log(err)
          })
      },
      handlerdepid(id) {
        postAction(this.url.deparUser, { depart_id: id })
          .then((res) => {
            console.log(res, '员工')
            this.tabledata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerrow(row) {
        console.log(row, '')
        this.user_id = row.user_id
        this.getrouterinfo()
      },
      getrouterinfo() {
        postAction(this.url.userline, { user_id: this.user_id })
          .then((res) => {
            console.log(res, '')
            if (res.data.line.rows.length < 1) {
              this.linelist = [
                {
                  line_id: '',
                  line_name: '',
                  tian: '',
                  sort: '',
                  cust_num: '',
                  enid: true,
                },
              ]
            } else {
              this.linelist = res.data.line.rows
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleradd(index) {
        console.log(index, '增加')
        this.linelist.splice(
          index + 1,
          0,
          JSON.parse(JSON.stringify(this.fanli[0]))
        )
        console.log(this.linelist, '')
      },
      handlerdelete(index) {
        if (this.linelist.length <= 1) {
          this.$notify({
            title: '警告',
            message: '不能再删除了！',
            type: 'warning',
          })
        } else {
          this.linelist.splice(index, 1)
        }
      },
      handlerrouterdata(val, row) {
        console.log(val, row)
        this.ruterlist.forEach((list) => {
          if (list.line_id == val) {
            row.cust_num = list.cust_num
            row.line_name = list.line_name
            row.tian = 0
          }
        })
      },
      handlerinfo(row) {
        console.log(row, '')
        this.$refs.cust.userinfo = true
        this.$refs.cust.handlerinfo(row)
      },
      handlersave() {
        var line_id = new Array()
        this.linelist.forEach((list) => {
          line_id.push(list.line_id)
        })
        postAction(this.url.save, {
          user_id: this.user_id,
          line_ids: JSON.stringify(line_id),
        })
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.getrouterinfo()
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerSetrouter() {
        this.$refs.schedule.Schedule = true
        this.$refs.schedule.getuserlist()
      },
      Setsort(row) {
        console.log(row, '')
        postAction(this.url.sort, {
          user_id: this.user_id,
          line_id: row.line_id,
          sort_order: row.sort,
        })
          .then((res) => {
            console.log(res, '')
            this.getrouterinfo()
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // fetchData(ky = '') {
      //   postAction(this.url.router, {})
      //     .then((res) => {
      //       console.log(res, '线路')
      //       // this.ruterlist = res.data
      //       return res.data
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // },
      // querySearch(queryString, cb) {
      //   if (queryString == '') {
      //     cb([])
      //   } else {
      //     this.fetchData(queryString).then((val) => {
      //       cb(val)
      //     })
      //   }
      // },
    },
  }
</script>

<style scoped>
  .wrapper {
    padding: 20px;
  }
  .row-bgs {
    margin-top: 30px;
  }
</style>
