<!--
 * @Author: your name
 * @Date: 2021-04-09 09:28:33
 * @LastEditTime: 2021-04-09 10:09:50
 * @LastEditors: Please set LastEditors
 * @Description: 终端详情
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\pathAllocation\components\CustList.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <el-dialog :modal="false" title="客户详情" center :visible.sync="userinfo">
      <div>
        <h3>线路：{{ routername }}</h3>
        <el-table stripe :data="userlist" height="600">
          <el-table-column
            v-for="(routers, index) in clumds"
            :key="index"
            :prop="routers.prop"
            :label="routers.label"
            :width="routers.width"
            :align="routers.align"
          ></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userinfo = !userinfo">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        userinfo: false,
        url: {
          routerinfo: '/baseAdmin/line/detail',
        },
        userlist: [],
        clumds: [
          {
            prop: 'sort',
            label: '拜访顺序',
            width: '',
            align: 'center',
          },
          {
            prop: 'cust_name',
            label: '客户名称',
            width: '',
            align: 'center',
          },
          {
            prop: 'address',
            label: '客户地址',
            width: '',
            align: 'center',
          },
          {
            prop: 'boss',
            label: '老板姓名',
            width: '',
            align: 'center',
          },
          {
            prop: 'mobile',
            label: '老板电话',
            width: '',
            align: 'center',
          },
        ],
        routername: '',
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handlerinfo(row) {
        this.routername = row.line_name
        postAction(this.url.routerinfo, { line_id: row.line_id })
          .then((res) => {
            console.log(res, '线路详情')
            this.userlist = res.data.cust.rows
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
