var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "设置线路排期",
            visible: _vm.Schedule,
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Schedule = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "user_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择员工" },
                          on: { change: _vm.handlerinfo },
                          model: {
                            value: _vm.form.user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "user_id", $$v)
                            },
                            expression: "form.user_id",
                          },
                        },
                        _vm._l(_vm.userlist, function (list, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: list.user_id,
                              label: list.user_name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "考勤组：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.attend_name))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "休息日：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.rest_day))]),
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.tiem,
                          callback: function ($$v) {
                            _vm.tiem = $$v
                          },
                          expression: "tiem",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerpaiqi },
                        },
                        [_vm._v(" 生成预排期 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-calendar", {
                scopedSlots: _vm._u([
                  {
                    key: "dateCell",
                    fn: function (ref) {
                      var data = ref.data
                      return [
                        _c(
                          "p",
                          { class: data.isSelected ? "is-selected" : "" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.day.split("-").slice(1).join("月")
                                ) +
                                " " +
                                _vm._s(data.isSelected ? "✔️" : "") +
                                " "
                            ),
                          ]
                        ),
                        _vm._l(_vm.paiqidata, function (list, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              data.day == list.date && list.is_rest == 0
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v(" 班 "),
                                  ])
                                : data.day == list.date && list.is_rest == 1
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(" 休 "),
                                  ])
                                : _vm._e(),
                              data.day == list.date
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { placeholder: "线路名称" },
                                      model: {
                                        value: list.line_id,
                                        callback: function ($$v) {
                                          _vm.$set(list, "line_id", $$v)
                                        },
                                        expression: "list.line_id",
                                      },
                                    },
                                    _vm._l(_vm.xianlu, function (item, indexs) {
                                      return _c("el-option", {
                                        key: indexs,
                                        attrs: {
                                          label: item.line_name,
                                          value: item.line_id,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.currentDate,
                  callback: function ($$v) {
                    _vm.currentDate = $$v
                  },
                  expression: "currentDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.handlersave } },
                [_vm._v("保存排期")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }