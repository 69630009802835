var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "客户详情",
            center: "",
            visible: _vm.userinfo,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userinfo = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("h3", [_vm._v("线路：" + _vm._s(_vm.routername))]),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.userlist, height: "600" } },
                _vm._l(_vm.clumds, function (routers, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: routers.prop,
                      label: routers.label,
                      width: routers.width,
                      align: routers.align,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userinfo = !_vm.userinfo
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }